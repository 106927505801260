<template>
  <div class="">
    <trac-loading v-if="isLoading" />
    <main v-if="!isLoading">
      <trac-validation-observer slim v-slot="{ handleSubmit }">
        <form
          @submit.prevent="handleSubmit(sendForm)"
          class="md:ml-12 mr-4 md:mr-0 mb-6"
        >
          <trac-back-button>{{
            fromCategoryModule ? 'Create Category' : 'Products'
          }}</trac-back-button>
          <div class="flex-col mt-12 mb-8">
            <h2>Products</h2>
            <h3 class="font-bold mt-4 text-2xl">
              {{ !$route.params.id ? 'Create a' : 'Edit' }} Product
            </h3>
          </div>
          <div class="bg-white px-8 py-8 rounded-lg big-shadow">
            <trac-custom-header>
              <template slot="leading">Product </template>
              <template slot="trailing">Details</template>
            </trac-custom-header>
            <div class="grid grid-cols-12 gap-5 pb-6">
              <div
                class="md:col-span-3 h-48 relative pr-3 border-r border-gray-200"
                v-if="product.image || imageToUpload.src"
              >
                <img
                  class="h-40 w-full block"
                  :src="imageToUpload.src || product.image"
                  alt=""
                />
                <input
                  class="absolute bottom-0"
                  accept="image/png, image/jpeg, image/jpg"
                  @change="selectImage"
                  type="file"
                  name=""
                  id="product-image"
                  v-show="false"
                />
                <button
                  @click="removeImage"
                  class="w-full p-2 text-sm bg-primaryGray text-white"
                >
                  Remove Image
                </button>
              </div>
              <div
                v-else
                class="flex-wrap flex-column md:col-span-3 pr-3 border-r border-gray-200"
              >
                <div class="flex w-full md:w-48 h-40 no-img-bg relative">
                  <input
                    class="absolute bottom-0"
                    accept="image/png, image/jpeg, image/jpg"
                    @change="selectImage"
                    type="file"
                    name=""
                    id="product-image"
                    v-show="false"
                  />
                  <div
                    @click="triggerFileOpen"
                    class="flex w-full md:w-48 cursor-pointer h-10 bg-primaryGray mt-auto text-white justify-center items-center font-medium text-sm"
                  >
                    Upload Image
                  </div>
                </div>
              </div>
              <div class="md:col-span-9 w-full justify-between mt-10 md:mt-0">
                <div class="w-full grid grid-cols-12 gap-x-12 gap-y-8">
                  <trac-validation-provider
                    class="col-span-6"
                    name="product name"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <trac-input
                      data-test="product-name"
                      placeholder="Product Name"
                      v-model="product.title"
                    ></trac-input>
                    <trac-input-error v-if="errors[0]">{{
                      errors[0]
                    }}</trac-input-error>
                  </trac-validation-provider>
                  <trac-validation-provider
                    class="col-span-6 relative"
                    name="product.category"
                  >
                    <trac-dropdown-exteneded
                      neededProperty="name"
                      :options="categories"
                      :selector="
                        product && product.id
                          ? product.categories.name
                          : 'Select Category'
                      "
                      @optionSelected="handleSelectCategory($event, 'category')"
                    ></trac-dropdown-exteneded>
                    <create-category @close="fetchAllCategories">
                      <template #default="{ toggleModal }">
                        <button
                          class="text-primaryBlue text-xs font-medium absolute right-0 top-0 -mt-5"
                          @click="toggleModal"
                        >
                          Add New Category
                        </button>
                      </template>
                    </create-category>
                    <!-- <trac-input-error v-if="errors[0]">{{
                      errors[0]
                    }}</trac-input-error> -->
                  </trac-validation-provider>
                  <div class="col-span-6 w-full relative">
                    <trac-dropdown-exteneded
                      neededProperty="name"
                      :disabled="
                        !product.id
                          ? !product.category
                          : !(
                              product &&
                              product.categories &&
                              product.categories.name
                            )
                      "
                      :options="subCategories"
                      :selector="subCatLabel"
                      @optionSelected="
                        handleSelectCategory($event, 'subCategory')
                      "
                    ></trac-dropdown-exteneded>
                    <create-sub-category
                      :category="
                        categories.find((cat) => cat.id === product.category) ||
                        {}
                      "
                      @close="fetchAllCategories"
                    >
                      <template #default="{ toggleModal }">
                        <button
                          class="text-primaryBlue text-xs font-medium absolute right-0 top-0 -mt-5"
                          @click="toggleModal"
                        >
                          Add New Subcategory
                        </button>
                      </template>
                    </create-sub-category>
                    <!-- <trac-input-error v-if="errors[0]">{{
                        errors[0]
                      }}</trac-input-error> -->
                  </div>
                </div>
                <div class="flex-column w-full mt-10 md:mr-24">
                  <trac-textarea
                    v-model="product.description"
                    placeholder="Description"
                  ></trac-textarea>
                </div>
                <div v-if="isOnline" class="mt-8 font-semibold text-sm">
                  <trac-toggle
                    @newval="updateVariationValue"
                    :active="product.has_variant"
                    v-model="product.has_variant"
                    >Does this product have variations
                  </trac-toggle>
                </div>
              </div>
            </div>

            <div
              v-if="product.id"
              class="text-xs text-primaryGray flex items-center gap-2 py-4"
            >
              <div class="w-3">
                <img
                  src="@/assets/svg/info.svg"
                  class="w-full"
                  alt="info-icon"
                />
              </div>
              Stocks can only be updated on the
              <router-link
                :to="{
                  name: 'StockAdjustment',
                }"
                class="underline"
              >
                Stock Adjustments
              </router-link>
              page
            </div>

            <options-table
              v-if="product.options && product.options.length"
              :options="product.options || []"
              @set-options="product.options = $event"
            />

            <ProductVariation
              v-if="
                (product.options &&
                  product.options.length &&
                  product.has_variant) ||
                (product.variations && product.variations.length)
              "
              :product="product"
              @toggle-add-options="
                () => {
                  optionsCanOpen = !optionsCanOpen;
                  toggleAddOptions = !toggleAddOptions;
                }
              "
              @update-variations="product.variations = $event"
            />
          </div>
          <AddOptions
            v-if="product.has_variant && optionsCanOpen"
            :product="product"
            :toggle-add-options="toggleAddOptions"
            @add-option="
              (e) => {
                product.options = [...product.options, { ...e }];
                optionsCanOpen = false;
              }
            "
            @close="
              () => {
                optionsCanOpen = false;
                product = {
                  ...product,
                  has_variant: false,
                };
              }
            "
          />
          <div v-if="isOnline">
            <div
              v-if="!product.has_variant"
              class="mt-5 bg-white px-8 py-8 rounded-lg overflow-hidden big-shadow"
            >
              <trac-custom-header>
                <template slot="leading">Product </template>
                <template slot="trailing">Detail</template>
              </trac-custom-header>

              <div v-if="false" class="mb-8 border rounded-lg overflow-scroll">
                <table class="md:w-full table-fixed">
                  <thead class="bg-blue-100">
                    <tr class="h-12">
                      <th
                        class="text-left text-sm font-semibold uppercase px-4 whitespace-no-wrap"
                      >
                        Variation
                      </th>
                      <th
                        class="text-left text-xs font-semibold uppercase px-4 whitespace-no-wrap"
                      >
                        SKU
                      </th>
                      <th
                        class="text-left text-xs font-semibold uppercase px-4 whitespace-no-wrap"
                      >
                        Cost Price
                      </th>
                      <th
                        class="text-left text-xs font-semibold uppercase px-4 whitespace-no-wrap"
                      >
                        Price
                      </th>
                      <th
                        class="text-left text-xs font-semibold uppercase px-4 whitespace-no-wrap"
                      >
                        Stock Available
                      </th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="h-12"
                      v-for="(variant, i) in product.varieties"
                      :key="i"
                    >
                      <td
                        class="text-xs font-bold capitalize px-4 whitespace-no-wrap"
                      >
                        {{ variant.title }}
                      </td>
                      <td
                        class="text-xs font-bold capitalize px-4 whitespace-no-wrap"
                      >
                        {{ variant.barcode }}
                      </td>
                      <td
                        class="text-xs font-bold capitalize px-4 whitespace-no-wrap"
                      >
                        {{ variant.cost_price | formatPrice }}
                      </td>
                      <td
                        class="text-xs font-bold capitalize px-4 whitespace-no-wrap"
                      >
                        {{ variant.price | formatPrice }}
                      </td>
                      <td
                        class="text-xs font-bold capitalize px-4 whitespace-no-wrap"
                      >
                        {{ variant.stock }}
                      </td>
                      <td>
                        <div class="flex justify-end pr-4">
                          <button
                            @click="editVariant(variant)"
                            type="button"
                            class="bg-none border-none text-xs text-primaryBlue"
                          >
                            Edit
                          </button>
                        </div>
                      </td>
                      <td>
                        <div class="flex justify-end pr-4">
                          <img
                            class="cursor-pointer"
                            src="../../assets/svg/close_btn.svg"
                            alt=""
                            @click="removeVariant(variant)"
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="m-5">
                  <trac-button
                    @button-clicked="openVariationModal"
                    variant="outline"
                    >Add Variation</trac-button
                  >
                </div>
              </div>

              <trac-modal
                v-if="variationModal"
                class="max-w-2xl w-full"
                @close="variationModal = false"
              >
                <AddVariation
                  @addVariant="variantAdded($event)"
                  @close="variationModal = false"
                  :stores="stores"
                  :allCreatedproducts="allCreatedproducts"
                  :variantToEdit="variantToEdit"
                ></AddVariation>
              </trac-modal>

              <div class="flex flex-col">
                <div class="grid md:grid-cols-3 md:w-10/12 relative gap-4">
                  <trac-validation-provider
                    name="price"
                    rules="required|greaterthanzero"
                    v-slot="{ errors }"
                  >
                    <trac-input
                      :disabled="product.has_variant"
                      placeholder="Selling Price"
                      data-test="price-field"
                      v-model.number="product.sale_price"
                    ></trac-input>
                    <trac-input-error v-if="errors[0]">{{
                      errors[0]
                    }}</trac-input-error>
                  </trac-validation-provider>

                  <trac-validation-provider
                    name="cost price"
                    rules=""
                    v-slot="{ errors }"
                  >
                    <trac-input
                      :disabled="product.has_variant"
                      data-test="cost-price"
                      placeholder="Cost Price"
                      v-model.number="product.cost_price"
                    ></trac-input>
                    <trac-input-error v-if="errors[0]">{{
                      errors[0]
                    }}</trac-input-error>
                  </trac-validation-provider>

                  <trac-input
                    :disabled="product.has_variant"
                    data-test="barcode-field"
                    v-model="product.barcode"
                    placeholder="SKU / Barcode"
                  ></trac-input>
                  <trac-button
                    class="uppercase mt-3"
                    variant="outline"
                    @button-clicked="generateBarcode"
                    data-test="barcode-button"
                    :disabled="product.has_variant"
                    >Generate SKU / Barcode
                  </trac-button>
                  <trac-button
                    class="uppercase mt-3"
                    variant="outline"
                    :disabled="product.has_variant"
                    data-test="scan-barcode"
                    @button-clicked="showBarcode = true"
                    >Scan SKU / Barcode
                  </trac-button>
                  <div
                    class="absolute right-0 mt-20 h-64 w-64"
                    v-if="showBarcode"
                  >
                    <button
                      class="text-xs cursor-pointer text-primaryBlue p-2"
                      @click="showBarcode = false"
                    >
                      Close scanner
                    </button>
                    <StreamBarcodeReader
                      @decode="onDecode"
                    ></StreamBarcodeReader>
                  </div>
                </div>
              </div>

              <!-- <h2 class="pt-8 pb-6 text-primaryBlue">Loyalty</h2>
              <div class="grid gap-2 md:flex items-center md:w-10/12">
                <div class="w-full flex">
                  <trac-radio
                    value="default"
                    name="loyalty-data"
                    :selectedVal="loyalty"
                    @change="loyalty = $event"
                    ><p class="text-xs">
                      Earn default loyalty points
                    </p></trac-radio
                  >
                  <div></div>
                </div>
                <div class="w-full flex items-center">
                  <trac-radio
                    value="custom"
                    name="loyalty-data"
                    :selectedVal="loyalty"
                    @change="loyalty = $event"
                    ><p class="text-xs">Create custom loyalty</p></trac-radio
                  >
                </div>
              </div>
              <div class="grid md:flex items-center md:w-10/12">
                <div class="w-full flex mt-4">
                  <svg
                    class="w-4 h-4"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.0003 1.66663C5.39783 1.66663 1.66699 5.39746 1.66699 9.99996C1.66699 14.6025 5.39783 18.3333 10.0003 18.3333C14.6028 18.3333 18.3337 14.6025 18.3337 9.99996C18.3337 5.39746 14.6028 1.66663 10.0003 1.66663ZM10.8337 14.1666H9.16699V9.16663H10.8337V14.1666ZM10.8337 7.49996H9.16699V5.83329H10.8337V7.49996Z"
                      fill="#979797"
                    />
                  </svg>
                  <div class="text-xs md:pr-20 ml-4 text-primaryGray">
                    This means that for every NGN 100 purchase your customer
                    <br />
                    makes, they get
                    {{ product.loyalty_points | formatPrice }} in loyalty
                    points.
                  </div>
                </div>
                <div v-if="loyalty == 'custom'" class="w-full">
                  <div class="w-64 mt-6 relative flex items-center">
                    <trac-input
                      v-model.number="product.loyalty_points"
                      class="w-full"
                      placeholder="Set Custom Percentage"
                    ></trac-input>
                    <div class="absolute text-primaryGray right-0 mr-5">%</div>
                  </div>
                </div>
              </div> -->
              <trac-modal
                v-if="comboModal"
                class="max-w-2xl w-full"
                @close="comboModal = false"
              >
                <AddCombo
                  :products="sortedProducts"
                  @add-combo="comboAdded($event)"
                ></AddCombo>
              </trac-modal>

              <div class="mt-6 w-8/12" v-if="product.combo">
                <div
                  v-for="(combo, i) in product.combo_items"
                  :key="i"
                  class="mt-3 flex flex-row gap-4 items-center"
                >
                  <div
                    class="flex flex-row bg-accentLight px-6 w-11/12 justify-between py-3 font-semibold text-xs"
                  >
                    <span>{{ combo.title }}</span>
                    <span class="">{{ combo.quantity || combo.stock }}</span>
                  </div>
                  <div>
                    <img
                      class="cursor-pointer"
                      src="../../assets/svg/close_btn.svg"
                      alt=""
                      @click="removeCombo(combo)"
                    />
                  </div>
                </div>
                <div class="mt-5">
                  <trac-button @button-clicked="openComboModal" variant="small"
                    >Add another item</trac-button
                  >
                </div>
              </div>
              <div class="mt-8 md:mr-32 pt-8 border-t font-semibold text-sm">
                <div class="py-4">
                  <trac-toggle
                    v-model="product.available_for_sale"
                    :active="product.available_for_sale"
                    @newval="
                      product = {
                        ...product,
                        available_for_sale: !$event,
                      }
                    "
                  >
                    Is this product available for sale?
                  </trac-toggle>
                </div>
                <!-- :disabled="updateProductFlag" -->
                <trac-toggle
                  v-if="!product.has_variant"
                  :disabled="product.id"
                  v-model="product.manage_stock"
                  :active="product.manage_stock"
                  @newval="updateManageStock"
                  >Would you like to manage stock for this item</trac-toggle
                >
              </div>
              <ManageStock
                v-if="product.manage_stock"
                :stores="stores"
                :product="product"
                @set-store-stock="product.store_stock = $event"
              />
            </div>
          </div>
          <div
            class="mt-5 bg-white px-8 py-8 rounded-lg overflow-hidden big-shadow"
          >
            <trac-custom-header>
              <template slot="leading">Product Tags, </template>
              <template slot="trailing">and modifiers</template>
            </trac-custom-header>
            <trac-tag-list
              :tags-items="product.tags"
              class="mt-6 mb-2"
              @update-tags="product.tags = $event"
            />
            <trac-modifiers-list
              v-if="!isLoadingProduct"
              :product="product"
              class="mt-6 mb-2"
              @update-modifiers="product.modifiers = $event"
            />
          </div>
          <ProductUnit
            v-if="!isLoading && !isLoadingProduct && product.has_variant"
            :product="product"
            @update-unit="Object.assign(product, $event)"
          />
          <div v-if="isOnline" class="flex flex-row mt-8 md:justify-end">
            <div class="flex-column md:w-12/12">
              <trac-button class="uppercase" buttonType="submit">
                {{ isEditMode ? 'Update' : 'Create' }}
                Product
              </trac-button>
            </div>
          </div>
        </form>
      </trac-validation-observer>
    </main>
  </div>
</template>

<script>
import {
  DELETE_LOCAL_DB_DATA,
  GET_LOCAL_DB_DATA,
  GET_USER_BUSINESS_DATA,
  SAVE_LOCAL_DB_DATA,
} from '../../browser-db-config/localStorage';
import AddVariation from './AddVariation';
import AddCombo from './AddCombo';
import AddOptions from './Options/AddOptions';
import ProductVariation from './ProductVariation.vue';
import ProductUnit from './ProductUnit.vue';
import { GENERATE_RANDOM_ID } from '../../browser-db-config/utilityMethods';
import { eventBus } from './../../main';
import { StreamBarcodeReader } from 'vue-barcode-reader';
import { permissionsModules } from './../settings/permission';
import { mapActions, mapGetters } from 'vuex';
import CreateCategory from '../categories/CreateCategory.vue';
import CreateSubCategory from '../categories/CreateSubCategory.vue';
import OptionsTable from './Options/OptionsTable.vue';
import { convertKeysToSnakeCase } from '../../utils';
import ManageStock from './ManageStock.vue';

export default {
  name: 'NewProduct',
  components: {
    AddVariation,
    AddCombo,
    StreamBarcodeReader,
    AddOptions,
    ProductVariation,
    CreateCategory,
    CreateSubCategory,
    OptionsTable,
    ProductUnit,
    ManageStock,
  },
  data() {
    return {
      toggleAddOptions: false,
      isLoading: false,
      updateProductFlag: false,
      fromCategoryModule: false,
      dataLoaded: false,
      showBarcode: false,
      permissionsModules,
      loyalty: 'default',
      product: {
        title: '',
        barcode: '',
        category: '',
        description: '',
        image: '',
        price: '',
        cost_price: '',
        stock: 0,
        varieties: [],
        variation: false,
        manage_stock: false,
        combo: false,
        combo_items: [],
        isModifier: false,
        tags: [],
        options: [],
        modifiers: [],
      },
      imageToUpload: {
        src: null,
        file: null,
      },
      stores: null,
      loyaltyData: null,
      variantToEdit: null,
      allCreatedproducts: null,
      variationModal: false,
      comboModal: false,
      categories: [],
      tags: [],
      variations: [],
      isLoadingProduct: false,
      optionsCanOpen: false,
      subCategories: [],
    };
  },
  computed: {
    ...mapGetters(['GET_PRODUCT_CATEGORIES']),
    isEditMode() {
      return !!this.$route.params.id;
    },
    subCatLabel() {
      return (
        this.subCategories?.find((s) => s.id === this.product.subCategory)
          ?.name ?? 'Select Subcategory'
      );
    },
    payloadForCREATE_PRODUCTS_V2() {
      const isProd = process.env.NODE_ENV === 'production';
      const defaultCheckoutID = !isProd
        ? '665ee4d1f0efe3332fcdddbe'
        : '67b65486ef3da9178af72495';
      return {
        title: this.product.title,
        description: this.product.description,
        cost_price: this.product.has_variant ? 0 : this.product.cost_price || 0,
        image: this.product.image,
        barcode: this.product.barcode,
        hasVariant: this.product.has_variant,
        hasModifier: !!this.product.modifiers?.length,
        sale_price: this.product.sale_price || 1,
        isModifier: false,
        category_id: this.product.category,
        sub_category_id: this.product.subCategory,
        // onSale: true,
        // onSalePeriod: {
        //   endDate: "2025-02-21T23:00:00.000",
        //   startDate: "2024-01-0T823:00:00.000",
        // },
        selling_value: this.product.selling_value,
        measurement_unit: this.product.measurement_unit,
        checkout_option_id: this.product.has_variant
          ? this.product.checkout_option_id || defaultCheckoutID
          : defaultCheckoutID,
        manage_stock: this.product.manage_stock,
        store_stock: this.product.store_stock?.length
          ? this.product.store_stock?.filter((st) => !!st?.stock_quantity)
          : undefined,
        tags: this.product.tags,
        options: this.product.options,
        variations: this.product.variations,
        modifiers: this.product.modifiers,
      };
    },
    sortedProducts() {
      return this.allCreatedproducts.sort((a, b) =>
        a.title > b.title ? 1 : -1
      );
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    ...mapActions([
      'FETCH_PRODUCTS_CATEGORIES',
      'FETCH_PRODUCT_V2',
      'UPDATE_PRODUCT_V2',
    ]),
    async fetchData() {
      await this.NetworkCheck();
      this.loyaltyData = GET_USER_BUSINESS_DATA().loyalty_active;

      if (this.loyaltyData) {
        this.loyalty = 'default';
      }

      // clear on the fly module
      DELETE_LOCAL_DB_DATA('create-category-on-the-fly');

      this.isLoading = true;
      this.updateProductFlag = GET_LOCAL_DB_DATA('product-details')
        ? GET_LOCAL_DB_DATA('product-details')._id
          ? true
          : false
        : false;

      if (this.updateProductFlag || GET_LOCAL_DB_DATA('product-details')) {
        this.product = GET_LOCAL_DB_DATA('product-details');
        this.getTags(this.product._id);
      }

      if (this.$route.params.id) {
        await this.fetchProduct();
      }

      await this.fetchAllStores();
      await this.fetchAllProducts();
      await this.fetchAllCategories();

      if (!this.$route.params.id) {
        this.product = {
          ...this.product,
          store_stock: this.stores.map((store) => {
            return {
              re_order: 0,
              stock_quantity: 0,
              store_id: store._id,
              store_name: store.name,
            };
          }),
        };
      }

      // Set category if any
      this.fromCategoryModule = GET_LOCAL_DB_DATA('category-detail');
      if (this.fromCategoryModule) {
        this.product.category = this.fromCategoryModule
          ? this.fromCategoryModule.name
          : '';
      }
      this.dataLoaded = true;
      this.isLoading = false;
    },
    async fetchProduct() {
      this.isLoadingProduct = true;
      try {
        const res = await this.FETCH_PRODUCT_V2({
          params: { id: this.$route.params.id },
        });

        this.product = {
          ...convertKeysToSnakeCase({ ...res.data }),
          checkout_option_id: res.data.checkoutOption?.id,
          has_variant: !!res.data?.hasVariant,
          modifiers: res.data?.modifiers || [],
          tags: res.data?.tags?.length
            ? res.data?.tags.map((tag) => ({
                _id: tag.id,
                tagName: tag.label,
              }))
            : undefined,
          store_stock: (res.data?.storeStock || []).map((st) => ({
            re_order: st?.reOrderLevel || 0,
            stock_quantity: st?.stockQuantity,
            store_id: st?.storeID,
            store_name: st?.storeName,
          })),
        };
      } catch (error) {
        eventBus.$emit('trac-alert', {
          message: error?.response?.data?.message,
        });
      } finally {
        this.isLoadingProduct = false;
      }
    },
    getTags(id) {
      this.$store.dispatch('FETCH_PRODUCT', id).then((res) => {
        if (res.status) {
          this.tags = res.data.tags;
        }
      });
    },
    onDecode(x) {
      if (x) {
        this.product.barcode = x;
        this.showBarcode = false;
      }
    },
    createCategoryOnTheFly() {
      if (
        permissionsModules[4]['parent']['permissionsForUser']['createCategory']
      ) {
        const payload = {
          origin: {
            // name: "Add Invoice",
          },
          product: this.product,
        };

        SAVE_LOCAL_DB_DATA('create-category-on-the-fly', payload);
        this.$router.push({ name: 'AddCategories' });
      } else {
        eventBus.$emit('trac-alert', {
          message: 'You have the permission to create a product.',
        });
      }
    },
    sendForm() {
      if (this.$route.params.id) {
        this.updateProduct();
      } else {
        this.createProduct();
      }
    },
    updateVariationValue(value) {
      this.product = {
        ...this.product,
        has_variant: !value,
      };
      if (!value) this.optionsCanOpen = true;
    },
    updateManageStock(value) {
      this.product.manage_stock = !value;
    },
    updateComboValue(value) {
      this.product.combo = !value;
    },
    openVariationModal() {
      this.variationModal = true;
    },
    openComboModal() {
      this.comboModal = true;
    },
    editVariant(variant) {
      this.variantToEdit = variant;
      this.variationModal = true;
    },
    variantAdded(variant) {
      if (!this.variantToEdit) {
        this.product.varieties.push({
          ...variant,
          clientID: GENERATE_RANDOM_ID(),
        });
      }
      this.variationModal = false;
    },
    removeVariant(variantProduct) {
      this.product.varieties = this.product.varieties.filter(
        (variant) => variantProduct.clientID !== variant.clientID
      );
    },
    comboAdded(comboProduct) {
      this.product.combo_items.push(comboProduct);
      this.comboModal = false;
    },
    removeCombo(comboProduct) {
      this.product.combo_items = this.product.combo_items.filter(
        (combo) => comboProduct.title !== combo.title
      );
    },
    async uploadProductImage() {
      this.isLoading = true;
      await this.$store.dispatch('UPLOAD_FILE', this.imageToUpload);
      const res = this.$store.getters['GET_UPLOAD_RES'];

      this.$store.commit('SETUP_FILE_UPLOADED', null);

      if (res.status) {
        this.product.image = res.data;
      } else {
        // alert(res.message);
        eventBus.$emit('trac-alert', { message: res.message });
      }
      this.isLoading = false;
    },
    async createProduct() {
      if (this.imageToUpload.file) {
        await this.uploadProductImage();
      }
      debugger;
      const res = await this.$store.dispatch('CREATE_PRODUCT_V2', {
        ...this.payloadForCREATE_PRODUCTS_V2,
        has_modifier: !!this.product.modifiers.length,
        hasModifier: !!this.product.modifiers.length,
        has_variant: !!this.product?.variations?.length,
        variations: this.product?.variations?.map((variation) => ({
          ...variation,
          store_stock: (variation.store_stock ?? [])
            .map(({ store_i_d, ...rest }) => ({
              ...rest,
              store_id: store_i_d || rest.store_id,
              re_order: rest.re_order_level || rest.re_order,
            }))
            ?.filter((store) => !!store?.stock_quantity),
        })),
        modifiers: this.product.modifiers.length
          ? this.product.modifiers.map((mod) => mod.id)
          : undefined,
        tags: this.product.tags?.length
          ? this.product.tags.map((tag) => ({
              id: tag._id,
              label: tag.tagName,
            }))
          : undefined,
      });
      // const res = this.$store.getters["GET_CREATED_PRODUCT_RES_V2"];

      // this.$store.commit("SETUP_CREATED_PRODUCT", null);

      if (res.status) {
        this.$router.back();
        eventBus.$emit('trac-alert', {
          message: 'Product created successfully',
        });
        // this.requestSync("products");
      } else {
        // alert(res.message);
        eventBus.$emit('trac-alert', { message: res.message });
      }
      this.isLoading = false;
    },
    async updateProduct() {
      this.isLoading = true;
      if (this.imageToUpload.file) {
        await this.uploadProductImage();
      }

      const payload = {
        ...this.payloadForCREATE_PRODUCTS_V2,
        has_modifier: !!this.product.modifiers.length,
        hasModifier: !!this.product.modifiers.length,
        has_variant: !!this.product?.variations?.length,
        variations: this.product?.variations?.map((variation) => ({
          ...variation,
          store_stock: (variation.store_stock ?? [])
            .map(({ store_i_d, ...rest }) => ({
              ...rest,
              store_id: store_i_d || rest.store_id,
              re_order: rest.re_order_level || rest.re_order,
            }))
            ?.filter((store) => !!store?.stock_quantity),
        })),
        modifiers: this.product.modifiers.length
          ? this.product.modifiers.map((mod) => mod.id)
          : undefined,
        tags: this.product.tags?.length
          ? this.product.tags.map((tag) => ({
              id: tag._id,
              label: tag.tagName,
            }))
          : undefined,
        updated_at: new Date().toISOString(),
        id: this.product.id,
      };

      try {
        const res = await this.UPDATE_PRODUCT_V2(payload);
        if (res?.status) {
          this.$router.back();
          eventBus.$emit('trac-alert', {
            message: 'Product modified successfully',
          });
        }
      } catch (error) {
        eventBus.$emit('trac-alert', { message: res.message });
      } finally {
        this.isLoading = false;
      }
    },
    triggerFileOpen() {
      const fileInput = document.querySelector('#product-image');
      fileInput.click();
    },
    selectImage(e) {
      this.imageToUpload.file = e.target.files[0];
      this.imageToUpload.src = window.URL.createObjectURL(
        this.imageToUpload.file
      );
    },
    removeImage() {
      this.imageToUpload = {
        src: null,
        file: null,
      };
      this.product.image = '';
    },
    specificProductStoreStock(store, variant) {
      let specificStore = null;
      const newStore = {};
      // if (this.product.has_variant) {
      //   this.product.varieties.forEach((prodVariant) => {
      //     prodVariant.store_stock.forEach((ss) => {
      //       if (
      //         ss.store_id === store._id &&
      //         prodVariant.barcode === variant.barcode
      //       ) {
      //         specificStore = ss;
      //       }
      //     });
      //   });
      // }

      specificStore = (this.product.store_stock || []).find((ss, index) => {
        // return ss.store_id === store._id || ss.store_id === store._id;
        return ss.store_id === store._id;
      });

      if (specificStore) {
        return specificStore;
      } else {
        // Assign to the store and return
        Object.assign(newStore, {
          store_id: store._id,
          store_name: store.name,
        });
        this.product.store_stock = [
          ...(this.product.store_stock || []),
          newStore,
        ];
        return newStore;
      }
    },
    async fetchAllStores() {
      await this.$store.dispatch('FETCH_ALL_STORES');
      const res = this.$store.getters['GET_ALL_STORES'];

      if (res.status) {
        this.stores = res.data || [];
      }
    },
    handleSelectCategory(category, key) {
      this.product = {
        ...this.product,
        [key]: category.id,
      };
      if (key === 'category') {
        this.subCategories = this.categories.find(
          (c) => c.id === category.id
        )?.sub_categories;
      }
    },
    async fetchAllCategories() {
      // const data = (await this.$GlobalOfflineManager.getAll("categories")) || [
      //   { name: "", count: 0 },
      // ];
      // const remappedData = data.map((cat) => {
      //   return {
      //     name: cat.category.name,
      //     id: cat.category._id,
      //     count: cat.category.count,
      //   };
      // });
      // this.categories = remappedData;

      try {
        const res = await this.FETCH_PRODUCTS_CATEGORIES();
        if (res.status) {
          this.categories = [...this.GET_PRODUCT_CATEGORIES];
          this.subCategories =
            this.categories.find((c) => c.id === this.product.categories?.id)
              ?.sub_categories ?? [];
          this.product.subCategory = this.product?.sub_categories?.id;
        }
      } catch (error) {
        eventBus.$emit('trac-alert', {
          message: error?.response?.data?.message,
        });
      }
    },
    async fetchAllProducts() {
      await this.$store.dispatch('FETCH_ALL_PRODUCTS');
      const res = this.$store.getters['GET_ALL_PRODUCT'];

      if (res.status) {
        this.allCreatedproducts = res.items || [];
      } else {
        // alert(res.message || "Error: Error with network.");
        eventBus.$emit('trac-alert', {
          message: res.message || 'Error: Error with network.',
        });
      }
    },
    async generateBarcode() {
      this.isLoading = true;
      await this.$store.dispatch('GENERATE_BARCODE');
      const res = this.$store.getters['GET_GENERATED_BARCODE'];

      this.$store.commit('POPULATE_GENERATED_BARCODE', null);

      if (res.status) {
        this.product.barcode = res.data;
      } else {
        // alert(res.message);
        eventBus.$emit('trac-alert', { message: res.message });
      }
      this.isLoading = false;
    },
  },
  watch: {
    'product.category'() {
      this.product.subCategory = '';
    },
    'product.has_variant'(val) {
      if (!val) {
        this.product = {
          ...this.product,
          options: [],
        };
      }
    },
    'product.options'(val) {
      if (val && !val?.length && !this.optionsCanOpen) {
        this.product.has_variant = false;
      }
    },
  },
};
</script>

<style scoped>
.no-img-bg {
  background-color: #e2e2e2;
}
</style>
