<template>
  <div class="mt-5 bg-white px-8 py-8 rounded-lg overflow-hidden big-shadow">
    <trac-custom-header class="border-b border-b-[#ECECEC] mb-6">
      <template slot="leading">Selling and Measuring Unit, </template>
      <template slot="trailing">Checkout behavior</template>
    </trac-custom-header>
    <h6 class="uppercase text-xs mb-12">
      <span class="font-bold">Measurement / Selling Unit</span> (Optional)
    </h6>

    <div class="grid grid-cols-12 gap-10 pb-8 mb-8 border-b border-b-[#ECECEC]">
      <div class="col-span-6">
        <trac-dropdown-exteneded
          data-test="selling-unit"
          neededProperty="name"
          :options="sellingUnits"
          :selector="
            (product.selling_value &&
              sellingUnits.find((unit) => unit.label === product.selling_value)
                .name) ||
            'Selling Unit'
          "
          @optionSelected="selectSellingUnit"
        ></trac-dropdown-exteneded>
      </div>
      <div class="col-span-6">
        <trac-input v-model="measurementUnit" placeholder="Measuring Unit" />
      </div>
    </div>

    <h6 class="font-bold uppercase text-xs mb-8">Checkout behavior</h6>

    <div class="grid grid-cols-12 gap-10 pb-8 mb-8">
      <div
        v-for="{ _id, name, description } in checkoutBehavior"
        :key="_id"
        class="col-span-6 flex gap-4"
      >
        <trac-checkbox
          :is-checked="_id === formData.checkout_option_id"
          :data-test="_id"
          @check="selectOptions"
          :value="_id"
        />
        <div class="flex flex-col gap-3">
          <div
            class="rounded-md overflow-hidden cursor-pointer"
            :class="{
              'border border-[#E3E3E3]': _id === formData.checkout_option_id,
            }"
            style="width: 225px; height: 225px"
            @click="selectOptions({ value: _id })"
          >
            <img
              v-if="name === 'Default'"
              src="@/assets/svg/product-variants-example.svg"
              :alt="description"
              class="w-full h-full"
            />
            <img
              v-else
              src="@/assets/svg/product-options-example.svg"
              :alt="description"
              class="w-full h-full"
            />
          </div>
          <div>
            <p>{{ name }}</p>
            <span class="text-xs text-accentDark">{{ description }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { eventBus } from '../../eventBus';

export default {
  name: 'ProductUnit',
  emits: ['update-unit'],
  props: {
    product: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      formData: {
        checkout_option_id: '',
      },
      sellingUnits: [
        { label: 'whole-number', name: 'Whole Number' },
        { label: 'decimals', name: 'Decimals' },
      ],
      checkoutBehavior: [],
    };
  },
  computed: {
    measurementUnit: {
      get() {
        return this.formData.measurement_unit;
      },
      set(val) {
        this.formData.measurement_unit = val;
        this.$emit('update-unit', { measurement_unit: val });
      },
    },
  },
  created() {
    this.formData.measurement_unit = this.product?.measurement_unit;
    this.formData.selling_value = this.product?.selling_value;
    this.fetchCheckoutOptions();
  },
  methods: {
    ...mapActions(['FETCH_CHECKOUT_OPTIONS']),
    async fetchCheckoutOptions() {
      try {
        const res = await this.FETCH_CHECKOUT_OPTIONS();
        this.formData.checkout_option_id =
          this.product?.checkout_option_id ||
          res.data.find((dt) => dt.name === 'Default')?._id;
        this.checkoutBehavior = res.data ?? [];
      } catch (error) {
        eventBus.$emit('trac-alert', {
          message: error?.response?.data?.message,
        });
      }
    },
    selectSellingUnit(unit) {
      this.formData.selling_value = unit.label;
      this.$emit('update-unit', { selling_value: this.formData.selling_value });
    },
    selectOptions({ value }) {
      this.formData.checkout_option_id = value;
      this.$emit('update-unit', {
        checkout_option_id: value,
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
