var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-5 bg-white px-8 py-8 rounded-lg overflow-hidden big-shadow"},[_c('trac-custom-header',{staticClass:"border-b border-b-[#ECECEC] mb-6"},[_c('template',{slot:"leading"},[_vm._v("Selling and Measuring Unit, ")]),_c('template',{slot:"trailing"},[_vm._v("Checkout behavior")])],2),_vm._m(0),_c('div',{staticClass:"grid grid-cols-12 gap-10 pb-8 mb-8 border-b border-b-[#ECECEC]"},[_c('div',{staticClass:"col-span-6"},[_c('trac-dropdown-exteneded',{attrs:{"data-test":"selling-unit","neededProperty":"name","options":_vm.sellingUnits,"selector":(_vm.product.selling_value &&
            _vm.sellingUnits.find((unit) => unit.label === _vm.product.selling_value)
              .name) ||
          'Selling Unit'},on:{"optionSelected":_vm.selectSellingUnit}})],1),_c('div',{staticClass:"col-span-6"},[_c('trac-input',{attrs:{"placeholder":"Measuring Unit"},model:{value:(_vm.measurementUnit),callback:function ($$v) {_vm.measurementUnit=$$v},expression:"measurementUnit"}})],1)]),_c('h6',{staticClass:"font-bold uppercase text-xs mb-8"},[_vm._v("Checkout behavior")]),_c('div',{staticClass:"grid grid-cols-12 gap-10 pb-8 mb-8"},_vm._l((_vm.checkoutBehavior),function({ _id, name, description }){return _c('div',{key:_id,staticClass:"col-span-6 flex gap-4"},[_c('trac-checkbox',{attrs:{"is-checked":_id === _vm.formData.checkout_option_id,"data-test":_id,"value":_id},on:{"check":_vm.selectOptions}}),_c('div',{staticClass:"flex flex-col gap-3"},[_c('div',{staticClass:"rounded-md overflow-hidden cursor-pointer",class:{
            'border border-[#E3E3E3]': _id === _vm.formData.checkout_option_id,
          },staticStyle:{"width":"225px","height":"225px"},on:{"click":function($event){return _vm.selectOptions({ value: _id })}}},[(name === 'Default')?_c('img',{staticClass:"w-full h-full",attrs:{"src":require("@/assets/svg/product-variants-example.svg"),"alt":description}}):_c('img',{staticClass:"w-full h-full",attrs:{"src":require("@/assets/svg/product-options-example.svg"),"alt":description}})]),_c('div',[_c('p',[_vm._v(_vm._s(name))]),_c('span',{staticClass:"text-xs text-accentDark"},[_vm._v(_vm._s(description))])])])],1)}),0)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h6',{staticClass:"uppercase text-xs mb-12"},[_c('span',{staticClass:"font-bold"},[_vm._v("Measurement / Selling Unit")]),_vm._v(" (Optional) ")])
}]

export { render, staticRenderFns }